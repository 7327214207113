/**
 * Viewport utilities functions is using for checking client size of the browser
 * Should not be used in the server side
 */

enum ViewportSize {
  MOBILE = 576,
  TABLET = 768,
  DESKTOP = 1280
}

export const getViewportWidth = () =>
  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

export const getViewportHeight = () =>
  Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

export const isDesktop = () => getViewportWidth() >= ViewportSize.DESKTOP
