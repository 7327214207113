import { useEffect, useRef } from 'react'
import { FCC } from '~/core/@types/global'
import { cn } from '~/core/ui/utils'
import { getViewportHeight, isDesktop } from '~/core/utilities/viewport'

interface Props {
  className?: string
  onScroll: (data: { percentage: number; index: number }) => void
  numberOfItems: number
}

const ScrollToAction: FCC<Props> = ({
  children,
  className,
  onScroll,
  numberOfItems
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  // calculate sticky top position base on screen height and content height
  useEffect(() => {
    const content = wrapperRef.current?.firstChild as HTMLDivElement
    if (!content) return

    const viewportHeight = getViewportHeight()
    const contentHeight = content.clientHeight || 0
    const top = Math.min((viewportHeight - contentHeight) / 2, 0)

    // set sticky top position of the content
    content.style.top = `${top}px`
  }, [wrapperRef])

  // Handle scroll to action
  useEffect(() => {
    let processing = false
    const content = wrapperRef.current?.firstChild as HTMLDivElement
    const desktopView = isDesktop()
    const contentHeight = content.clientHeight || 0

    const handleScroll = () => {
      // only support desktop
      if (!desktopView) return

      // throttle to avoid too many calculation
      if (processing) return
      processing = true
      setTimeout(() => {
        processing = false
      }, 50)
      // end throttle

      const wrapperBound = wrapperRef.current?.getBoundingClientRect()
      const wrapperTop = wrapperBound?.y || 0

      // still not reach the section
      if (wrapperTop >= 0) {
        onScroll({
          percentage: 0,
          index: 0
        })
        return
      }

      const wrapperHeight = wrapperBound?.height || 0
      const scrollHeight = wrapperHeight - contentHeight
      const scrollWrapperTop = Math.abs(wrapperTop)

      const sliderIndex = Math.ceil(
        scrollWrapperTop / (scrollHeight / numberOfItems)
      )
      const scrollPercentage = Math.min(scrollWrapperTop / scrollHeight, 1)

      onScroll({
        percentage: scrollPercentage,
        index: Math.min(sliderIndex - 1, numberOfItems - 1)
      })
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [numberOfItems, onScroll])

  /**
   * To handle scroll to next slide, the approach we are using is creating a wrapper with a long height (3000px)
   * (can be adjusted base on expected)
   * then set content sticky on top. base on scroll position, we calculate the index of slide to show
   */

  return (
    <div ref={wrapperRef} className={cn(className)}>
      {children}
    </div>
  )
}

export default ScrollToAction
